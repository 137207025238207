<template>
  <v-card
    class="rounded mx-2 my-2 enrolledCourse course-item"
    :class="{ 'grey darken-4': $vuetify.theme.dark }"
    :key="'trainingCourse-' + item.id"
    :to="{
      name: 'training-course',
      params: {
        id: item.courseId,
        topic: item.topicId,
        enrollmentDetails: item,
      },
    }"
  >
    <v-card-actions class="py-0 header-top">
      <span
        style="font-size: 12px"
        class="font-weight-bold primary--text d-inline-block"
        v-if="item.format === 'On-Demand'"
      >
        {{ $t("c_enrollment.t_on_demand") }}
      </span>
      <span style="font-size: 12px" class="font-weight-bold primary--text d-inline-block" v-else>
        {{ $t("c_enrollment.t_webinar") }}
      </span>

      <span
        style="font-size: 12px"
        class="font-weight-bold ml-8 success--text d-inline-block"
        v-if="item.enrolledOn && !item.endedOn"
      >
        {{ $t("c_enrollment.t_enrolled") }} {{ formatDateFull(item.enrolledOn) }}
      </span>
      <v-spacer></v-spacer>
      <span
        style="font-size: 12px"
        class="font-weight-bold green--text d-inline-block"
        v-if="item.webinarPassed || item.bestExamPassed"
      >
        {{ $t("c_enrollment.t_completed") }} {{ formatDateFull(item.certificateLastIssuedOn) }}</span
      >
      <span style="font-size: 12px" class="font-weight-bold orange--text d-inline-block" v-else-if="item.expiresOn">
        {{ isDateExpired(item.expiresOn) ? "! Due" : "Due" }}
        {{ formatDateFull(item.expiresOn) }}
      </span>
    </v-card-actions>
    <v-list-item class="pl-0">
      <v-list-item-avatar class="my-0 ml-3">
        <v-icon size="45" color="green darken-1">mdi-school-outline</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle class="my-0 py-0" style="max-height: 26px">
          <div class="overline grey--text">
            {{ item.shortCode }}
          </div>
        </v-list-item-subtitle>

        <v-list-item-title class="text-subtitle-1">
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-subtitle class="py-1">
          {{ item.summary }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon class="my-auto ml-auto px-1" :class="parent">
        <v-icon v-if="item.format == 'On-Demand'" color="secondary" large>mdi-television-play</v-icon>
        <v-icon v-else color="secondary" large>mdi-monitor-eye</v-icon>
        <v-badge
          v-if="parent == 'trainingCourses'"
          :color="item.webinarPassed || item.bestExamPassed ? 'success' : 'orange darken-2'"
          :icon="
            !item.webinarPassed && !item.bestExamPassed ? 'mdi-progress-clock' : 'mdi-checkbox-marked-circle-outline'
          "
          bordered
          overlap
          top
          offset-x="10"
          offset-y="10"
        ></v-badge>
      </v-list-item-icon>
    </v-list-item>
  </v-card>
</template>

<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "EnrollmentListItem",
  mixins: [Helpers],
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application .grey.darken-4 {
  border-right: 5px solid #00c853 !important;
}

.course-item {
  .header-top {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.theme-dark {
  .course-item {
    .header-top {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
