<template>
  <div class="bg-app vh-100">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="py-0 pb-10" fluid :class="$vuetify.breakpoint.mobile ? 'px-0' : 'px-2'">
        <v-alert
          v-if="!$online"
          :style="$vuetify.breakpoint.mobile ? '' : 'margin: 0 -8px !important'"
          dense
          tile
          color="gray"
          icon="mdi-alert-circle-outline"
          class="alert-offline text-headline"
        >
          {{ $t("t_no_connection") }}
        </v-alert>
        <v-row>
          <v-col>
            <div
              class="clock-timer-dash px-1 mt-0"
              style="margin-left: 3px"
              :class="$vuetify.breakpoint.mobile ? 'pt-2 px-3' : 'pt-3 px-1'"
            >
              <clock-timer>
                <template v-slot:refresh>
                  <v-btn icon class="float-right btn-refresh" :class="{ rotate: loading }" @click="refresh"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                  >
                </template>
              </clock-timer>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="statement && statement.header">
          <v-col cols="12">
            <v-card flat tile class="mx-auto">
              <v-card-text>
                <div>{{ formatDate(statement.header.internalSentOn) }}</div>
                <p class="headline">{{ statement.header.subject }}</p>
                <div class="text--primary" v-html="statement.fullText"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pr-2' : 'mt-0'">
            <v-card class="mx-auto bg-main" flat tile>
              <MessageList ref="messages" :parent="'dashboard'" :messagesTakeNum="2">
                <template v-slot:header="{ messages }">
                  <v-card-subtitle class="d-flex pt-2 pb-0">
                    <p class="heading text-h6 mb-0">{{ $t("t_messages_preview_header") }}</p>
                    <v-spacer></v-spacer>
                    <v-btn small class="mt-1" v-if="messages.length" text :to="{ name: 'messages' }">
                      {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-subtitle>
                </template>
              </MessageList>
            </v-card>
            <v-card class="mx-auto bg-main mt-4" flat tile>
              <FormAssignments ref="assignments" :showFilters="false" :showWeekHeader="false" :takeNum="5"
                ><template v-slot:header="{ assignments }">
                  <v-list-item two-line style="min-height: 36px">
                    <v-list-item-content class="pb-0 pt-2">
                      <div class="text-overline ma-0 pa-0">
                        {{ formatDate(new Date().toISOString()) }}
                      </div>
                      <v-card-subtitle class="ma-0 pa-0 d-flex">
                        <p class="heading text-h6 mb-0">{{ $t("t_assignments_preview_header") }}</p>
                        <v-spacer></v-spacer>
                        <v-btn small class="mt-1" v-if="assignments.length" text :to="{ name: 'forms' }">
                          {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-card-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </FormAssignments>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? '' : 'my-4'">
            <v-card class="mx-auto bg-main" flat tile v-if="$root.nav.includes('documents')">
              <DocumentList ref="documents" :documentsTakeNum="5" :showFilters="false" :preview="true">
                <template v-slot:header="{ documents }">
                  <v-card-subtitle class="d-flex">
                    <p class="heading text-h6 mb-0">{{ $t("t_documents_preview_header") }}</p>
                    <v-spacer></v-spacer>
                    <v-btn small class="mt-1" v-if="documents.length" text :to="{ name: 'documents' }">
                      {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-subtitle>
                </template>
              </DocumentList>
            </v-card>
            <v-card class="mx-auto bg-main mt-4" flat tile>
              <TrainingCourseList ref="trainingCourses" :showFilters="false" :showWeekHeader="false" :takeNum="5"
                ><template v-slot:header="{ trainingCourses }">
                  <v-list-item two-line style="min-height: 36px">
                    <v-list-item-content class="pb-0 pt-2">
                      <v-card-subtitle class="ma-0 pa-0 d-flex">
                        <p class="heading text-h6 mb-0">{{ $t("t_training") }}</p>
                        <v-spacer></v-spacer>
                        <v-btn small class="mt-1" v-if="trainingCourses.length" text :to="{ name: 'training' }">
                          {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                        </v-btn>
                      </v-card-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </TrainingCourseList>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </VuePullRefresh>
    <div class="btn-logit-wrap">
      <v-fab-transition>
        <v-btn
          @click="doLogit"
          class="btn-logit text-capitalize"
          outlined
          fixed
          dark
          absolute
          bottom
          right
          fab
          large
          tile
          color="primary"
        >
          <!-- <v-icon large>mdi-camera</v-icon> -->
          <div class="btn-content" style="display: flex; flex-direction: column; align-items: center">
            <img width="38px" style="position: relative; top: -4px" src="~@/assets/log-it-logo-opacity.png" />
            <span class="font-weight-bold btn-logit-text">Log-It</span>
          </div>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import MessageList from "@/components/messages/MessageList.vue";
import FormAssignments from "@/components/forms/FormAssignments.vue";
import DocumentList from "@/components/documents/DocumentList.vue";
import TrainingCourseList from "@/components/training/TrainingCourseList.vue";
import ClockTimer from "@/components/timeclock/ClockTimer.vue";
import VuePullRefresh from "vue-pull-refresh";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard",
  },
  mixins: [Helpers],
  components: {
    MessageList,
    FormAssignments,
    DocumentList,
    TrainingCourseList,
    ClockTimer,
    VuePullRefresh,
  },
  computed: {
    ...mapGetters("timeClock", ["timeClocks", "activeTimeClock"]),
    computedMessages() {
      let computedMessages = [];
      this.messages.forEach((message) => {
        let sender = message.fromUserFriendlyName?.split(" ");
        if (sender) {
          message.fromUserFirstName = sender[0] ? sender[0] : "";
          message.fromUserLastName = sender[1] ? sender[1] : "";
        }
        computedMessages.push(message);
      });
      return computedMessages;
    },
  },
  data: () => ({
    loading: false,
    messages: [],
    assignments: [],
    statement: {},
    activeMessage: {
      header: {},
    },
    pullKey: 0,
  }),
  methods: {
    ...mapActions("message", ["getMessages", "getMessage", "getMessageLatestCompany", "getMessageLatestSystem"]),
    ...mapActions("forms", ["getFormAssignments"]),
    doLogit() {
      this.$router.push({ name: "issue", params: { evt: "trigger" } });
    },
    appRefresh() {
      this.refresh();
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
    showMessage(messageId) {
      this.getMessage(messageId).then((response) => {
        this.activeMessage = response.data;
        this.$refs.messagedialog.open(this.activeMessage);
        let messageIndex = this.messages.findIndex((message) => message.messageId == this.activeMessage.header.id);
        this.$set(this.messages[messageIndex], "internalReadOn", true);
      });
    },
    refresh() {
      this.$refs.messages?.loadMessages(true);
      this.$refs.assignments?.loadAssignments(true);
      this.$refs.documents?.loadDocuments(true);
    },
  },
};
</script>
<style lang="scss">
.theme--dark {
  .btn-logit {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}
.btn-logit {
  border-radius: 8px;
  bottom: 80px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  //background-color: rgba(0, 0, 0, 0.8) !important;
  border-width: 2px;
  height: 80px !important;
  width: 80px !important;
  .btn-logit-text {
    margin-top: 2px;
  }
}
@media (min-width: 1261px) {
  .btn-logit {
    bottom: 20px !important;
  }
}
</style>
