var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:'trainingCourse-' + _vm.item.id,staticClass:"rounded mx-2 my-2 enrolledCourse course-item",class:{ 'grey darken-4': _vm.$vuetify.theme.dark },attrs:{"to":{
    name: 'training-course',
    params: {
      id: _vm.item.id,
      topic: _vm.item.topicId,
    },
  }}},[_c('v-card-actions',{staticClass:"py-0 header-top"},[(_vm.item.format === 'On-Demand')?_c('span',{staticClass:"font-weight-bold primary--text d-inline-block",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("c_training.t_on_demand"))+" ")]):_c('span',{staticClass:"font-weight-bold primary--text d-inline-block",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("c_training.t_webinar"))+" ")]),_c('v-spacer'),(_vm.item.enrolledOn)?_c('span',{staticClass:"orange--text d-inline-block",class:_vm.isDateExpired(_vm.item.expiresOn) ? 'text--darken-3' : '',staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.isDateExpired(_vm.item.expiresOn) ? "! Due" : "Due")+" "+_vm._s(_vm.formatDateShort(_vm.item.expiresOn))+" ")]):_vm._e()],1),_c('v-list-item',{staticClass:"pl-0"},[_c('v-list-item-avatar',{staticClass:"my-0 ml-3"},[_c('v-icon',{attrs:{"size":"45","color":"grey darken-1"}},[_vm._v("mdi-school-outline")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"my-0 py-0",staticStyle:{"max-height":"26px"}},[_c('div',{staticClass:"overline grey--text"},[_vm._v(" "+_vm._s(_vm.item.shortCode)+" ")])]),_c('v-list-item-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('v-list-item-subtitle',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.item.summary)+" ")]),_c('v-list-item-subtitle',[(_vm.item.submission && _vm.item.submission.submittedOn)?_c('span',{staticClass:"green--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$t("c_training.t_submitted"))+" "+_vm._s(_vm.formatDateTime(_vm.item.submission.submittedOn))+" ")]):_vm._e()])],1),_c('v-list-item-icon',{staticClass:"my-auto ml-auto px-1",class:_vm.parent},[(_vm.item.format == 'On-Demand')?_c('v-icon',{attrs:{"color":"secondary","large":""}},[_vm._v("mdi-television-play")]):_c('v-icon',{attrs:{"color":"secondary","large":""}},[_vm._v("mdi-monitor-eye")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }