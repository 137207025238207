var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',{key:_vm.item.messageId,staticClass:"message-item rounded mx-2 pr-1 mb-1",class:{ 'grey darken-4': _vm.$vuetify.theme.dark },attrs:{"disabled":!_vm.$online},on:{"click":function($event){$event.stopPropagation();return _vm.showMessage(_vm.item)}}},[(_vm.item.companyWide)?_c('v-icon',{staticClass:"mr-3",attrs:{"large":"","color":"gray lighten-1"}},[_vm._v("mdi-domain")]):(_vm.item.systemNotice)?_c('v-icon',{staticClass:"mr-3",attrs:{"large":"","color":"gray lighten-1"}},[_vm._v("mdi-broadcast")]):_c('div',{staticClass:"mr-4"},[_c('app-user-avatar',{attrs:{"user":{
        profileImageAssetId: _vm.item.from?.profileImageAssetId
          ? _vm.item.from.profileImageAssetId
          : _vm.item.fromUserProfileImageAssetId,
        profileImageAssetKey:
          _vm.item.from?.profileImageAssetKey && _vm.item.from.profileImageAssetKey !== '00000000-0000-0000-0000-000000000000'
            ? _vm.item.from.profileImageAssetKey
            : _vm.item.fromUserProfileImageAssetKey,
        initials: _vm.item.from?.initials ? _vm.item.from.initials : _vm.item.fromUserInitials,
        userId: _vm.item.from?.id ? _vm.item.from.id : _vm.item.fromUserId,
      },"size":32}})],1),_c('v-list-item-content',[(_vm.item)?_c('v-list-item-subtitle',{staticClass:"text-caption"},[(_vm.item.companyWide)?_c('span',[_vm._v(_vm._s(_vm.$t("c_messagelistitem.t_company_message_from"))+" ")]):(_vm.item.systemNotice)?_c('span',[_vm._v(_vm._s(_vm.$t("c_messagelistitem.t_system_message"))+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.item.fromUserFriendlyName))])]):_vm._e(),_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.item.subject)}})],1),(_vm.item.isNew)?_c('v-list-item-action',{staticClass:"mx-2 mr-4 mt-6"},[_c('v-scroll-x-transition',[(_vm.showNew)?_c('v-badge',{attrs:{"color":"orange darken-3","content":_vm.$t('c_messagelistitem.t_new')}}):_vm._e()],1)],1):_vm._e(),_c('v-list-item-action-text',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.formatDateTimeContextual(_vm.item.sentOn))+" ")]),_c('v-list-item-action',{staticClass:"ml-2"},[(_vm.item.readOn)?_c('v-icon',{attrs:{"small":"","color":"green lighten-1"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"small":"","color":"grey darken-1"}},[_vm._v("mdi-checkbox-blank-circle")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }