<template>
  <div v-if="!loading">
    <slot name="header" :messages="messages"></slot>
    <v-list
      style="background: transparent !important"
      flat
      two-line
      v-if="computedMessages && computedMessages.length"
      :class="{ transparent: $vuetify.theme.dark }"
    >
      <template v-for="(item, index) in computedMessages">
        <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
        <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
        <MessageListItem
          v-else
          ref="messageListItem"
          :item="item"
          :parent="'dashboard'"
          :key="'message-' + index"
          @showMessage="showMessage(item)"
        />
      </template>
    </v-list>
    <h4 class="pa-4" v-else-if="!loading">{{ $t("c_messagelist.t_no_messages") }}</h4>
    <v-btn
      v-if="parent != 'dashboard' && showLoadMore && !loading && messages && messages.length > 0 && $online"
      class="mx-3 mb-3"
      @click="loadMoreMessages"
      >{{ $t("t_load_more") }}</v-btn
    >
    <MessageDialog ref="messagedialog" :message="activeMessage" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import MessageDialog from "@/components/messages/MessageDialog.vue";
import MessageListItem from "@/components/messages/MessageListItem.vue";

export default {
  name: "MessageList",
  mixins: [Helpers],
  components: { MessageDialog, MessageListItem },
  props: {
    messagesTakeNum: {
      type: Number,
      default: 20,
    },
    parent: {
      type: String,
      default: "messages",
    },
  },
  computed: {
    ...mapGetters("message", ["lastMessageId"]),
    computedMessages() {
      let computedMessages = [];
      if (!this.messages) {
        return;
      }
      this.messages.forEach((m) => {
        let sender = m.fromUserFriendlyName?.split(" ");
        if (sender) {
          m.fromUserFirstName = sender[0] ? sender[0] : "";
          m.fromUserLastName = sender[1] ? sender[1] : "";
        }
        if (this.$options.lastMessageIdCheck && m.id > this.$options.lastMessageIdCheck) {
          m.isNew = true;
        }
        computedMessages.push(m);
      });
      return computedMessages;
    },
  },
  //$options:
  lastMessageIdCheck: null,
  data: () => ({
    loading: false,
    activeMessage: {
      header: {},
    },
    messages: [],
    messagesOrderByField: "Id",
    messagesAscending: false,
    messagesSkipNum: 0,
    showLoadMore: false,
  }),
  methods: {
    ...mapActions("message", ["getMessages", "getMessage"]),
    showMessage(message) {
      //offline support would require a read record for read messages sync
      if (!this.$online) {
        return;
      }
      this.getMessage(message.id).then((r) => {
        this.activeMessage = r.data;
        this.$refs.messagedialog.open(this.activeMessage);
        let messageIndex = this.messages.findIndex(
          (message) => message.notificationTrackingId == this.activeMessage.notificationTrackingId,
        );
        this.$set(this.messages[messageIndex], "readOn", true);
      });
    },
    loadMessages(refresh = false) {
      this.showLoadBar();
      let messageParams = {
        "paging.orderbyfield": this.messagesOrderByField,
        "paging.ascending": this.messagesAscending,
        "paging.skipnum": this.messagesSkipNum,
        "paging.takenum": this.messagesTakeNum,
      };
      if (refresh) {
        this.loading = true;
        this.messages = [];
      }
      this.getMessages(messageParams).then((r) => {
        let messages = r.data;
        messages.forEach((m) => {
          this.messages.push(m);
        });
        if (r.meta?.endOfResults) {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }
        this.loading = false;
        this.hideLoadBar();
      });
    },
    loadMoreMessages() {
      this.messagesSkipNum = this.messages.length;
      this.loadMessages();
    },
  },
  mounted() {
    this.$options.lastMessageIdCheck = this.lastMessageId;
    this.loadMessages();
  },
};
</script>
