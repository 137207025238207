<template>
  <v-dialog v-model="dialog" max-width="800" min-height="400" @keydown.esc="cancel" @click:outside="close()">
    <v-card outlined v-if="message">
      <v-card-text class="pt-4" :class="{ 'grey darken-3': $vuetify.theme.dark }">
        {{ formatDateTime(message.sentOn) }}<br />
        <span>{{ message.fromUserFriendlyName }}</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title class="headline word-break"><span v-html="message.subject"></span></v-card-title>
      <v-card-text class="my-4">
        <div v-html="message.content"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="close()"> {{ $t("t_close") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "MessageDialog",
  mixins: [Helpers],

  data() {
    return {
      dialog: false,
      message: null,
    };
  },

  methods: {
    open(activeMessage) {
      this.dialog = true;
      this.message = activeMessage;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
