<template>
  <v-list-item
    class="document-item rounded mx-2 pr-1 mb-1"
    :class="{ 'grey darken-4': $vuetify.theme.dark }"
    :key="item.documentId"
    :to="{ name: 'document', params: { id: item.id } }"
  >
    <v-list-item-content>
      <v-list-item-subtitle v-if="item" class="text-caption">
        {{ item.tags }}
      </v-list-item-subtitle>
      <v-list-item-title class="text-wrap">{{ item.name }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon class="mr-2"> <v-icon color="grey lighten-1">mdi-chevron-right</v-icon> </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "DocumentListItem",
  mixins: [Helpers],
  props: {
    item: {
      type: Object,
    },
    parent: {
      type: String,
    },
  },
};
</script>
